<template>
    <div>
        <v-dialog
            v-model="itemDialog"
            :retain-focus="false"
            width="600"
            persistent
        >
            <v-card>
                <v-card-title class="pa-4 pb-0">{{ title }}</v-card-title>
                <v-card-text class="pa-4 pt-0">
                    <v-form v-model="validItem" ref="addItemForm">
                        <v-row>
                            <v-col cols="12">
                                <v-combobox
                                    v-model="selectedCostItem"
                                    clearable
                                    hide-details="auto"
                                    :items="items"
                                    item-text="name"
                                    label="Name"
                                    return-object
                                    :rules="[rules.required]"
                                    @change="selectCostItem"
                                />
                            </v-col>
                            <v-row class="mx-1">
                                <v-col cols="" v-if="costItem">
                                    <v-text-field
                                        v-model="costItem.quantity"
                                        type="number"
                                        hide-details="auto"
                                        label="Quantity"
                                        :rules="[rules.floatNumber]"
                                    />
                                </v-col>
                                <v-col cols="" v-if="costItem">
                                    <v-autocomplete
                                        hide-details="auto"
                                        :rules="[rules.required]"
                                        :items="units"
                                        label="Units"
                                        @change="changeUnit"
                                        v-model="costItem.unit"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col
                                    v-if="activateWeight && costItem"
                                    cols=""
                                >
                                    <v-text-field
                                        v-model="costItem.weight"
                                        hide-details="auto"
                                        label="Weight"
                                    />
                                </v-col>
                            </v-row>
                            <v-col cols="6" v-if="costItem">
                                <v-text-field
                                    v-model="costItem.cost"
                                    hide-details="auto"
                                    label="Cost"
                                    :rules="[rules.required]"
                                />
                            </v-col>
                            <v-col cols="6" v-if="costItem">
                                <v-autocomplete
                                    hide-details
                                    label="Currency"
                                    v-model="costItem.currency"
                                    :items="itemsCurrency"
                                    :rules="[rules.required]"
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pa-4">
                    <v-btn color="secondary" text @click="closeItemDialog">
                        Cancel
                    </v-btn>
                    <v-spacer />
                    <v-btn
                        color="primary"
                        :disabled="!validItem"
                        text
                        @click="saveCostItem"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-expansion-panels mandatory flat class="mb-4">
            <v-expansion-panel class="mb-4">
                <v-expansion-panel-header
                    color="grey lighten-3"
                    expand-icon="mdi-plus"
                    class="pa-2"
                    :style="{ 'min-height': 'unset' }"
                >
                    {{
                        section.reference
                            ? section.reference.toUpperCase()
                            : section.name.toUpperCase()
                    }}
                    <template v-slot:actions>
                        <v-btn
                            icon
                            @click="openItemDialog()"
                            :disabled="
                                (quoteStatus !== 'OPEN' &&
                                    quoteStatus !== 'APPROVAL') ||
                                    version
                            "
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-card class="elevation-0">
                        <v-data-table
                            :headers="headers"
                            color="grey lighten-3"
                            class="elevation-0"
                            :items="sectionItems"
                            :items-per-page="-1"
                            :mobile-breakpoint="0"
                        >
                            <template v-slot:[`item.weight`]="{ item }">
                                <div :style="{ 'min-width': '60px' }">
                                    <span v-if="item.weight">{{
                                        item.weight
                                    }}</span>
                                    <span v-else>--</span>
                                </div>
                            </template>
                            <template v-slot:[`item.quantity`]="{ item }">
                                <div :style="{ 'min-width': '40px' }">
                                    {{ item.quantity }}
                                </div>
                            </template>
                            <template v-slot:[`item.unit`]="{ item }">
                                <div :style="{ 'min-width': '60px' }">
                                    {{ item.unit }}
                                </div>
                            </template>
                            <template v-slot:[`item.cost`]="{ item }">
                                <div :style="{ 'min-width': '60px' }">
                                    {{ formatCurrency(item.cost) }}
                                </div>
                            </template>
                            <template v-slot:[`item.parcial`]="{ item }">
                                <div :style="{ 'min-width': '230px' }">
                                    {{ formatCurrency(item.parcial) }}
                                </div>
                            </template>
                            <template v-slot:[`item.percentage`]="{ item }">
                                <div :style="{ 'min-width': '80px' }">
                                    {{ item.percentage }}%
                                </div>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-btn
                                    icon
                                    small
                                    @click="openItemDialog(item)"
                                    :disabled="
                                        (quoteStatus !== 'OPEN' &&
                                            quoteStatus !== 'APPROVAL') ||
                                            version
                                    "
                                >
                                    <v-icon small>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn
                                    icon
                                    small
                                    @click="deleteItem(item)"
                                    :disabled="
                                        (quoteStatus !== 'OPEN' &&
                                            quoteStatus !== 'APPROVAL') ||
                                            version
                                    "
                                >
                                    <v-icon small>mdi-trash-can</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                        <v-dialog
                            v-model="deleteDialog"
                            persistent
                            max-width="400px"
                        >
                            <v-card>
                                <v-card-title class="text-h5"
                                    >Delete Cost</v-card-title
                                >
                                <v-card-text>
                                    Are you sure you want to delete this cost?
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn
                                        text
                                        color="secondary"
                                        @click="deleteDialog = false"
                                    >
                                        Close
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        @click="deleteCostItem"
                                        text
                                        color="error"
                                        :disabled="!permission"
                                    >
                                        Delete
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
export default {
    name: 'NewSectionForm',
    props: {
        section: Object,
        quoteCurrency: String,
        quoteStatus: String,
        quoteExchange: Number,
        itemSections: Array,
        createCost: Boolean,
        settingsExchange: Object,
        settingsUnits: Object,
        reload: Boolean,
        version: Boolean,
    },
    data() {
        return {
            itemDialog: false,
            costItem: {},
            units: [],
            sectionTotal: 0,
            sectionTotalPercentage: 0,
            totalWeight: 0,
            newTotal: 0,
            title: null,
            deleteDialog: false,
            permission: false,
            sectionItems: [],
            items: [],
            validItem: false,
            editingCostItem: -1,
            selectedCostItem: null,
            itemsCurrency: [],
            rules: {
                required: v => !!v || 'Required',
                number: v => (!isNaN(v) && parseInt(v) > 0) || 'Invalid number',
                floatNumber: v => (!isNaN(v) && v > 0) || 'Invalid number',
            },
            headers: [
                {
                    text: 'Name',
                    value: 'name',
                    class: 'grey lighten-3',
                    width: '250px',
                },
                {
                    text: 'Weight',
                    align: 'start',
                    value: 'weight',
                    class: 'grey lighten-3',
                },
                {
                    text: 'Qty',
                    align: 'start',
                    value: 'quantity',
                    class: 'grey lighten-3',
                },
                {
                    text: 'Unit',
                    align: 'start',
                    value: 'unit',
                    class: 'grey lighten-3',
                },
                {
                    text: 'Cost',
                    align: 'start',
                    value: 'cost',
                    class: 'grey lighten-3',
                },
                {
                    text: `Parcial`,
                    align: 'start',
                    value: 'parcial',
                    class: 'grey lighten-3',
                },
                {
                    text: '%',
                    value: 'percentage',
                    align: 'start',
                    class: 'grey lighten-3',
                },
                {
                    text: 'Actions',
                    sortable: false,
                    value: 'actions',
                    align: 'center',
                    class: 'grey lighten-3',
                },
            ],
            activateWeight: true,
        }
    },
    computed: {
        ...mapState(['totalCost']),
    },
    watch: {
        reload: function() {
            if (!this.reload) {
                this.headers = [
                    {
                        text: 'Name',
                        value: 'name',
                        class: 'grey lighten-3',
                        width: '250px',
                    },
                    {
                        text: 'Weight',
                        align: 'start',
                        value: 'weight',
                        class: 'grey lighten-3',
                    },
                    {
                        text: 'Qty',
                        align: 'start',
                        value: 'quantity',
                        class: 'grey lighten-3',
                    },
                    {
                        text: 'Unit',
                        align: 'start',
                        value: 'unit',
                        class: 'grey lighten-3',
                    },
                    {
                        text: 'Cost',
                        align: 'start',
                        value: 'cost',
                        class: 'grey lighten-3',
                    },
                    {
                        text: `Parcial`,
                        align: 'start',
                        value: 'parcial',
                        class: 'grey lighten-3',
                    },
                    {
                        text: '%',
                        value: 'percentage',
                        align: 'start',
                        class: 'grey lighten-3',
                    },
                    {
                        text: 'Actions',
                        sortable: false,
                        value: 'actions',
                        align: 'center',
                        class: 'grey lighten-3',
                    },
                ]
            }
        },
        totalCost: function(newTotal) {
            this.itemPercentage(newTotal)
        },
        createCost: function(newValue) {
            if (newValue) {
                this.setSections()
            }
        },
        'costItem.unit'() {
            if (this.selectedCostItem) {
                this.changeUnit()
            }
        },
    },
    methods: {
        changeUnit() {
            const unit = this.settingsUnits.measureUnits.find(
                element => element.abbr === this.costItem.unit
            )
            if (unit.measure !== 'mass') {
                this.costItem.weight = null
                this.activateWeight = false
            } else if (
                this.costItem.unit === 'qt' ||
                this.costItem.unit === 'QT'
            ) {
                this.costItem.weight = null
                this.activateWeight = false
            } else {
                this.activateWeight = true
            }
        },
        openItemDialog(item) {
            if (item) {
                this.selectedCostItem = _.cloneDeep(item)
                if (
                    this.selectedCostItem.currency !=
                    this.settingsExchange.globalCurrency
                ) {
                    const u = this.settingsExchange.exchanges.find(
                        i => i.currency == this.selectedCostItem.currency
                    )
                    let exchange = u.money
                    const convert = this.selectedCostItem.cost / exchange
                    this.selectedCostItem.cost = convert.toFixed(2)
                }
                this.editingCostItem = this.sectionItems.findIndex(
                    i => i.id === item.id
                )
                this.costItem = _.cloneDeep(this.selectedCostItem)
            }
            this.title = this.section.reference
                ? this.section.reference
                : this.section.name
            // Alphabetical order of items
            this.items = this.section.costItems.sort((a, b) => {
                if (a.name > b.name) {
                    return 1
                } else if (a.name < b.name) {
                    return -1
                } else {
                    return 0
                }
            })
            this.itemDialog = true
        },
        closeItemDialog() {
            this.editingCostItem = -1
            this.$refs.addItemForm.reset()
            this.itemDialog = false
            this.costItem = {}
            this.selectedCostItem = null
        },

        deleteItem(item) {
            this.permission = this.deletepermission()
            this.deleteDialog = true
            this.itemToDelete = item
        },
        saveCostItem() {
            if (
                this.costItem.currency != this.settingsExchange.globalCurrency
            ) {
                const u = this.settingsExchange.exchanges.find(
                    i => i.currency == this.costItem.currency
                )
                let exchange = u.money
                const convert = this.costItem.cost * exchange
                this.costItem.costExchange = this.costItem.cost
                this.costItem.cost = convert.toFixed(2)
            }
            if (this.costItem.weight) {
                if (this.costItem.unit === 'lb') {
                    this.costItem.weight = parseFloat(
                        this.costItem.weight * 0.453592
                    )
                    this.costItem.unit = 'kg'
                }
                this.costItem.parcial =
                    this.costItem.weight *
                    this.costItem.quantity *
                    this.costItem.cost
                this.costItem.totalWeight =
                    this.costItem.weight * this.costItem.quantity
            } else {
                this.costItem.totalWeight = 0
                this.costItem.parcial =
                    this.costItem.quantity * this.costItem.cost
            }
            const newItem = _.cloneDeep(this.costItem)
            if (this.editingCostItem >= 0) {
                this.sectionItems.splice(this.editingCostItem, 1, newItem)
            } else {
                newItem.id = newItem.id ? newItem.id : uuidv4()
                this.sectionItems.push(newItem)
            }
            this.sectionTotal = this.sectionItems.reduce(
                (acumulator, item) => acumulator + item.parcial,
                0
            )
            this.totalWeight = this.sectionItems.reduce(
                (acumulator, item) => acumulator + item.totalWeight,
                0
            )
            const parcial = this.sectionTotal
            this.headers[5].text = `Parcial = ${this.formatCurrency(
                this.sectionTotal
            )}`
            const sectionName = this.section.name
            const totalW = this.totalWeight
            this.$emit('totalCostFunction', parcial, sectionName, totalW)
            this.$emit('saveItems', sectionName, this.sectionItems)
            this.closeItemDialog()
            this.sectionTotal = null
        },
        deletepermission() {
            let items = 0
            this.itemSections.forEach(doc => {
                items += doc.items.length
            })
            if (items <= 1) {
                return false
            } else {
                return true
            }
        },
        deleteCostItem() {
            const indexToDelete = this.sectionItems.findIndex(
                i => i.id === this.itemToDelete.id
            )
            this.sectionItems.splice(indexToDelete, 1)
            this.sectionTotal = this.sectionItems.reduce(
                (acumulator, item) => acumulator + item.parcial,
                0
            )
            this.totalWeight = this.sectionItems.reduce(
                (acumulator, item) => acumulator + item.totalWeight,
                0
            )
            this.sectionTotalPercentage = this.sectionItems.reduce(
                (acumulator, item) => acumulator + item.percentage,
                0
            )
            const parcial = this.sectionTotal
            this.headers[5].text = `Parcial = ${this.formatCurrency(
                this.sectionTotal
            )}`
            this.headers[6].text = `${this.sectionTotalPercentage.toFixed(2)} %`
            const sectionName = this.section.name
            const totalW = this.totalWeight
            this.$emit('totalCostFunction', parcial, sectionName, totalW)
            this.$emit('saveItems', sectionName, this.sectionItems)
            this.sectionTotal = null
            this.deleteDialog = false
        },
        itemPercentage(newTotal) {
            var multiplier = 100
            for (const item of this.sectionItems) {
                if (newTotal) {
                    const percentage = (item.parcial / newTotal) * 100
                    item.percentage =
                        Math.round(percentage * multiplier) / multiplier
                } else {
                    item.percentage = 100
                }
            }
            this.sectionTotalPercentage = this.sectionItems.reduce(
                (acumulator, item) => acumulator + item.percentage,
                0
            )
            this.headers[6].text = `${this.sectionTotalPercentage.toFixed(2)} %`
            this.$forceUpdate()
        },
        selectCostItem(item) {
            if (typeof item == 'string') {
                this.costItem.name = item
            } else if (!item) {
                this.$refs.addItemForm.reset()
                this.costItem = {}
            } else {
                this.costItem = _.cloneDeep(item)
            }
        },
        setSections() {
            let total = 0
            this.itemSections.forEach(section => {
                for (const item of section.items) {
                    total += item.parcial
                }
            })
            this.itemSections.forEach(section => {
                for (const item of section.items) {
                    item.percentage = Number(
                        ((item.parcial / total) * 100).toFixed(2)
                    )
                }
            })
            this.sectionItems = []
            if (this.itemSections.length != 0) {
                const index = this.itemSections.findIndex(
                    element => element.name == this.section.name
                )
                if (index >= 0) {
                    this.sectionTotal = 0
                    this.sectionTotalPercentage = 0
                    this.itemSections[index].items.forEach(item => {
                        item.id = item.id ? item.id : uuidv4()
                        this.sectionTotal += item.parcial
                        this.sectionTotalPercentage += item.percentage
                        this.sectionItems.push(item)
                    })
                }
                this.headers[5].text = `Parcial = ${this.formatCurrency(
                    this.sectionTotal
                )}`
                this.headers[6].text = `${this.sectionTotalPercentage.toFixed(
                    2
                )} %`
            }
        },
        formatCurrency: function(amount) {
            return new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: this.settingsExchange.globalCurrency,
                minimumFractionDigits: this.quoteCurrency === 'COP' ? 0 : 2,
            }).format(amount)
        },
    },
    created() {
        this.setSections()
    },
    mounted() {
        this.itemsCurrency = this.settingsExchange.exchanges.map(exchange => {
            return exchange.currency
        })
        this.settingsUnits.measureUnits.forEach(x => {
            this.units.push(x.abbr)
        })
    },
}
</script>

<style scoped>
.no-border {
    border: none !important;
}
tr:not(:last-child) > td.border-grey {
    border-bottom: thin solid #eeeeee !important;
}
tr:hover {
    background: unset !important;
}
th,
td {
    font-size: 0.875rem !important;
}
.v-data-table >>> table {
    border: thin solid #eeeeee;
}
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
    padding: 0;
}
.v-expansion-panels {
    z-index: 0;
}
</style>
